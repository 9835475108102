import api from '@/services/api.js';
import dataTable from '@/components/DataTable.vue';
import filtroComponent from '@/components/DataFiltro.vue';
import dataHeader from '@/components/DataHeader.vue';


const consultar = {
    components: {
        'data-table': dataTable,
        'filtro-component': filtroComponent,
        'data-header': dataHeader
    },
    data() {
        const that = this;
        return {
            filtros: [],
            params: {
                page: 1, //pag inicial
                order: 'Id',
                direction: 'desc',
                perPage: 20, //qtd registros por paginas
            },
            selected: [],
            itemSelecionado: [],
            result: {},
            selecionado: '',
            modals: { show: false },
            actionButtons: []

        };
    },
    methods: {
        handleTrocarVisao: function (naoConsulta) {

            let visao = this.filtros.visao.filter(f => f.id === this.filtros.visaoSelecionada)[0];
            this.limparFiltros(this.filtros);

            for (let i in visao.filtro) {
                let filtro = this.filtros.data.filter(f => f.id === visao.filtro[i].id)[0];
                filtro.active = true;
                if (filtro.type === 'date') {
                    filtro.valuede = visao.filtro[i].valuede;
                    filtro.valueate = visao.filtro[i].valueate;
                }
                else {
                    filtro.value = visao.filtro[i].value;
                }

            }
            this.params.page = 1;
            if (!naoConsulta) {
                this.handleConsultar();
            }
        },
        handleConsultar: function (page) {
            if (page) {
                this.params.page = page;
            }
            let filters = this.montarFiltro(this.filtros, this.params);

            api.get(this.url + '?' + filters)
                .then((response) => {
                    this.result = response.data;
                    this.selected.pop();
                    this.itemSelecionado.pop();
                    this.changeCheckboxCallback(null);
                    if (this.callback) {
                        this.callback(response);
                    }
                });
        },
        handleOrdenar: function (field) {
            this.ordenar(field);
            this.handleConsultar(1);
        },
        handleRowClick: function (item) {
            let toggle = this.selected[0] === item.Id;
            this.selected.pop();
            if (!toggle) {
                this.selected.push(item.Id);
            }
            this.changeCheckbox(item);
        },
        handleExcelButton: function ($event) {
            _consultar.export($event.target)
        },
        callback: function () { },
        changeCheckboxCallback: function (item) {
            let buttons = this.actionButtons.filter(a => a.visibleOnRowSelected);
            if (item) {
                let name = item.Nome || item.Descricao || item.Id;
                this.selecionado = `Selecionado: <b>${name}</b>`;
                for (let i in buttons) {
                    buttons[i].visible = true;
                }
            }
            else {
                this.selecionado = '';
                for (let i in buttons) {
                    buttons[i].visible = false;
                }
            }
        },
        getModalTitle: function () {
            return this.itemSelecionado.length && this.itemSelecionado[0].Id !== 0 ? 'Editar' :  'Criar novo';
        },
        salvar: function () {
            this.$root.errors = [];
            api
                .post(this.url, this.itemSelecionado[0])
                .then(response => {
                    console.log(response.data);
                    if (response.data.success) {

                        //busco id do item que retornou no array
                        let x = this.result.Records.filter(i => i.Id === response.data.obj.Id);
                        //se encontreim substituo o item
                        if (x.length) {
                            this.result.Records.splice(this.result.Records.indexOf(x[0]), 1, response.data.obj);
                        }
                        //se n�o encontrei, insiro ele na primeira posi��o
                        else {
                            this.result.Records.splice(0, 0, response.data.obj);
                        }
                        this.modals.show = false;
                        this.itemSelecionado.pop();
                        this.selected.pop();
                        this.changeCheckboxCallback();
                    }
                    else {
                        this.$root.errors = response.data.errors;
                    }
                });

        },
        cancelar: function () {
            this.modals.show = false;

            if (this.itemSelecionado[0].Id !== 0) {
                this.result.Records.splice(this.result.Records.indexOf(this.itemSelecionado[0]), 1, JSON.parse(this.clone));
            }
            this.errors = [];
            this.itemSelecionado.pop();
            this.selected.pop();
            this.changeCheckboxCallback(null);
        },
        handleEditarButton: function () {
            if (this.itemSelecionado.length) {
                this.$root.errors = [];
                this.clone = JSON.stringify(this.itemSelecionado[0]);
                this.modals.show = true;
            }
            else {
                displayMessage({
                    type: 'warning',
                    msg: 'Selecione um item.'
                });
            }
        },
        handleNovoButton: function () {
            this.$root.errors = [];
            this.selected.pop();
            this.itemSelecionado.pop();
            this.itemSelecionado.push({ Id: 0, Ativo: true });

            this.modals.show = true;
        },

        //Helpers
        limparFiltros: function (filtros) {
            for (var i in filtros.data) {
                filtros.data[i].active = false;
                filtros.data[i].value = [];
            }
        },
        montarFiltro: function (filtros) {
            let filtro = '?';
            let arr = [];
            for (let i in this.params) {
                arr.push(i + '=' + this.params[i]);
            }

            let filtrosSelecionados = filtros.data.filter(f => f.active && (f.value || (f.source && f.source.some(s => s.checked))));
            let valores = filtrosSelecionados.map(f => (f.source && (!f.sourceType)) ? f.value.map(s => `${f.id}=${s}`) : `${f.id}=${f.value}`);
            let filtrosDeDataSelecionados = filtros.data.filter(f => f.active && (f.valuede || f.valueate));
            for (var i in filtrosDeDataSelecionados) {
                if (filtrosDeDataSelecionados[i].valuede) {
                    valores.push(`${filtrosDeDataSelecionados[i].id}de=${filtrosDeDataSelecionados[i].valuede}`);
                }
                if (filtrosDeDataSelecionados[i].valueate) {
                    valores.push(`${filtrosDeDataSelecionados[i].id}ate=${filtrosDeDataSelecionados[i].valueate}`);
                }
            }

            for (let x in valores) {
                if ($.isArray(valores[x])) {
                    for (let j in valores[x]) {
                        arr.push(valores[x][j]);
                    }
                }
                else {
                    arr.push(valores[x]);
                }

            }


            return arr.join('&');
        },
        ordenar: function (field) {

            if (this.params.order === field) {
                if (this.params.direction === 'asc') {
                    this.params.direction = 'desc';
                }
                else {
                    this.params.direction = 'asc';
                }
            }
            else {
                this.params.order = field;
                this.params.direction = 'desc';
            }
        },
        changeCheckbox: function (item) {

            let toggle = ($.isArray(this.itemSelecionado) ? this.itemSelecionado[0] : this.itemSelecionado) === item;
            this.itemSelecionado.pop();
            if (!toggle) {
                this.itemSelecionado.push(item);
            }

            if (this.changeCheckboxCallback) {
                this.changeCheckboxCallback(!toggle ? item : null);
            }
        },
        resolveCheckboxClass: function () {
            return 'primary';
        },
        resolveStatusClass: function () {
            return 'dark';
        },
        exportar: function ($el) {
            let that = this;
            let filtro = that.montarFiltro(that.filtros, that.params);
            filtro += `&tela=${that.url}&export=true`;
            that.$root.export($el, filtro);
        },
        print: function ($el) {
            let that = this;
            let filtro = that.montarFiltro(that.filtros, that.params);
            filtro += `&tela=${that.url}&export=true`;
            that.$root.print($el, filtro);
        },
        imprimir: function () {
            
            let win = window.open('');
            let filtrosAplicados = this.filtros.data.filter(f => f.active)
                .map(f => `${f.title}: ${f.type == 'date' ? 'de ' + (f.valuede ? f.valuede.split('-').reverse().join('-') : '') + ' at� ' + (f.valueate ? f.valueate.split('-').reverse().join('-') : '') : f.source ? f.source.filter(s => s.checked).map(s => s.title).join(', ') : f.value}`)
                .map(xit => '<li>' + xit + '</li>').join('');
            let header = `
                                    <div class="title">
		                                <b class="title-text">${$('title').text()}</b>
                                    </div>
                                    <div>
	                                    <div class="img-container">
		                                    <img src="${location.origin}${$('.logo img').attr('src')}"/>
	                                    </div>
	                                    <div class="filtros-container">
		                                    <ul>
			                                    ${filtrosAplicados}
		                                    </ul>
	                                    </div>
                                    </div>`;
            win.document.head.innerHTML = '<link href="' + window.location.origin + '/css/print.css?v=3" rel="stylesheet" />';
            win.document.body.innerHTML = header;
            win.document.body.innerHTML += $('.records').html();
            $(win.document).ready(function () {
                win.print();
            });
        }
    },
    mounted: function () {
        this.$nextTick(function () {
            this.handleConsultar(1);
        });
    }
    
};

export default consultar;

<template>
  <header class="card-header main-header">
    <div class="row h-100">
      <div class="col-lg-2" v-if="filtros.visao && filtros.visao.length">
        <select class="form-control pre-filtro" @change="handleTrocarVisao" v-model="filtros.visaoSelecionada">
          <template v-for="f in filtros.visao">
            <option :key="f.id"  :value="f.id">{{ f.title }}</option>
          </template>
        </select>
      </div>
      <div :class="'col-lg-'+(filtros.visao && filtros.visao.length ? '6' : '8')">
        <div class="row">
          <div class="col-lg-6">
            <h2 class="card-title" v-if="itemSelecionado.length && itemSelecionado[0].Id !== 0">
              <slot name="header" :itemSelecionado="itemSelecionado"></slot>
              <slot name="subheader" :params="params"></slot>
            </h2>
          </div>
          <div class="col-lg-6">
            <slot name="desc"></slot>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card-actions">
          <slot name="buttons" :params="params" :itemSelecionado="itemSelecionado"></slot>
          <template v-for="btn in actionButtons.filter(b => b.className.includes('padrao'))">
            <button v-if="btn.visible"
                    :key="btn.id"
                    :class="btn.className + ' btn btn-sm btn-'+btn.color"
                    @click="btn.callback"
                    :title="btn.title">
              <i 
                 v-if="btn.icon"
                 :style="{color: btn.iconColor || 'inherit'}"
                 :class="btn.icon"></i> {{btn.text}}
            </button>
          </template>
          
        </div>
      </div>
    </div>
  </header>
</template>

<script>

//DATAHEADER
export default {
    props: ['handleTrocarVisao', 'filtros', 'itemSelecionado','actionButtons','params'],
    
};
</script>


<template>
  <div :class="'p0'+ (filtros.visible ? ' h-100 col-lg-2' : ' h-0')">
    <span title="Exibir filtro" v-if="!filtros.visible" class="toggle-filtro exibir" @click="handleToggleFiltro">
      <i class="fas fa-angle-right"></i>
    </span>
    <span title="Esconder filtro" v-if="filtros.visible" class="toggle-filtro esconder" @click="handleToggleFiltro">
      <i class="fas fa-angle-left"></i>
    </span>

    <div :class="'card-body card-filtro scrollable has-scrollbar'+(filtros.visible ? '' : ' p0')">
      <div class="actions mb-3" v-if="filtros.data && filtros.data.length">
        <button class="btn btn-primary btn-sm pull-left"
                @click="handleFiltrarButton">
          <i class="fas fa-filter"></i> Filtrar
        </button>
        <button class="btn btn-default btn-sm pull-right" @click="handleLimparFiltro">
          Limpar
        </button>
      </div>
      <slot name="buttons"></slot>

      <div class="filtro-container row">
        <item-filter v-for="filtro in filtros.data"
                     :key="filtro.id"
                     :id="filtro.id"
                     :filtro="filtro"
                     @ativa-filtro="filtro.active = !filtro.active">
        </item-filter>
        <slot name="custom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
//FILTRO
export default {
    
    props: ['filtros', 'params', 'handleConsultar'],
    methods: {
        
        handleToggleFiltro: function () {
            this.filtros.visible = !this.filtros.visible;
        },
        handleLimparFiltro: function () {

            this.limparFiltros(this.filtros);
            this.params.page = 1;
            this.handleConsultar();
        },
        limparFiltros: function (filtros) {
            for (var i in filtros.data) {
                filtros.data[i].active = false;
                filtros.data[i].value = [];
            }
        },
        handleFiltrarButton() {
            this.handleConsultar(1);
        }


    },
};
</script>

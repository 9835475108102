<template>
  <div :class="'h-100 '+ (filtros.visible ? 'col-lg-10 p0' : 'col-lg-12')">
    <div :class="'card-body card-tabela ' + (filtros.visible ? '' : 'p0')">
      <div class="h-100">
        <div :class="'table-responsive records ' + classTableContainer || ''">
          <table class="table table-bordered table-hover mb-0 table-sm" v-if="result && result.Columns">
            <thead>
              <tr>
                <th class="checkbox-column">

                </th>
                <template v-for="(c) in result.Columns.filter(c => c.Display)">
                  <th
                      :class="c.Display"
                      :key="c.Display"
                      @click="handleOrdenar(c.Order)"
                      :title="c.Title">
                    <span>
                      {{c.Display}}
                    </span>
                    <span class="pull-right">
                      <i v-if="params.order == c.Order"
                         :class="'el ' + (params.direction == 'desc' ? 'el-chevron-down' : 'el-chevron-up')">

                      </i>
                    </span>
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item) in result.Records">
                <tr :key="item.Id"
                    @click="handleRowClick(item)"
                    :title="typeof(title) === 'function' ? title(item) : title"
                    :class="(item === itemSelecionado ? 'active' : '')"
                    :data-id="item.Id">
                  <td>
                    <div :class="'checkbox-custom pull-right checkbox-'+ (typeof(checkboxClass) === 'function' ? checkboxClass(item) : checkboxClass)">
                      <input type="checkbox"
                             v-model="selected"
                             class="check-table"
                             :value="item.Id"
                             @change="($event) => $event.preventDefault()"
                             :id="'table' + item.Id">
                      <label :for="'table' + item.Id"></label>
                    </div>
                  </td>
                  <template v-for="(c) in result.Columns.filter(c => c.Display)">
                    <td :key="c.Property" :class="c.Property">
                      <span v-if="!c.Property.toLowerCase().includes('status')">{{item[c.Property]}}</span>
                      <span v-if="c.Property.toLowerCase().includes('status')" :class="'badge badge-'+(typeof(resolveStatusClass) === 'function' ? resolveStatusClass(item[c.Property]) : resolveStatusClass)">{{item[c.Property]}}</span>

                    </td>
                  </template>
                  
                </tr>
              </template>
              
            </tbody>
            <tfoot>
              <slot name="tfoot"></slot>
            </tfoot>
          </table>
        </div>

        <slot name="table2" :selected="selected" :result="result" :params="params">

        </slot>

        <pagination :pagination="result.Pagination"
                    :params="params"
                    :footerContent="footerContent"
                    @trocar-pagina="(page) => { handleConsultar(page) }">
        </pagination>

      </div>
    </div>
  </div>
</template>

<script>

export default {
    data() {
        return {
            menus: [1, 2, 3, 4, 5, 6]
        }
    },
    props: ['result', 'params', 'title', 'handleRowClick', 'checkboxClass', 'resolveStatusClass', 'handleOrdenar', 'itemSelecionado', 'selected', 'classTableContainer', 'filtros', 'footerContent','handleConsultar'],
    methods: {
        
        clickHandler: function (emit, p, p2) {
            this.$emit(emit, p, p2);
        }
    },
  };
</script>
